.gallery {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 500px;
  grid-auto-flow: dense;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlayImage:hover {
  opacity: 0.3;
}
.MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.MuiInputBase-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px grey inset !important;
}
@media (max-width: 1000px) {
  .gallery {
    display: flex;
    flex-direction: column;
  }
}
